/**
 *
 * @param {*} data {title: string; url: string }
 * @returns Boolean if share was successful
 */
export default async function (data) {
  if (!navigator.canShare) {
    return false;
  }

  let successful = false;

  const shareData = {
    title: data.title,
    url: data.url,
  };

  successful = await navigator.share(shareData);

  return successful;
}
