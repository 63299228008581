/**
 *
 * @param {*} props {title: string; url: string}
 */
export default function (props) {
  const config = {
    title: props?.title ?? "",
    url: props?.url?.replace(/ /g, "+") ?? "",
  };

  const buildTwitterUrl = () => {
    const url = new URL("https://twitter.com/intent/tweet");

    Object.entries(config).forEach(([key, val]) => {
      url.searchParams.set(key, val);
    });
    return url;
  };

  const buildFacebookUrl = () => {
    const query = { u: config.url };
    const url = new URL("https://www.facebook.com/sharer/sharer.php");
    Object.entries(query).forEach(([key, val]) => {
      url.searchParams.set(key, val);
    });
    return url;
  };

  const buildWhatsappUrl = () => {
    const text = `${config?.title} - ${config?.url}`;
    const query = {
      text: text,
    };
    const url = new URL("https://api.whatsapp.com/send");
    Object.entries(query).forEach(([key, val]) => {
      url.searchParams.set(key, val);
    });
    return url;
  };

  return {
    twitter: buildTwitterUrl(),
    facebook: buildFacebookUrl(),
    whatsapp: buildWhatsappUrl(),
    // instagram,
  };
}
