<template>
  <tippy
    :arrow="false"
    :placement="placement"
    interactive
    :inlinePositioning="true"
    :onMount="onInit"
  >
    <slot></slot>
    <template #content>
      <div
        class="rounded-lg border border-[#856D6D] flex flex-row justify-center items-center bg-brand-red-surface m-2 relative"
        :class="tipClasses"
      >
        <svg
          viewBox="0 0 40 24"
          class="stroke-2 w-[24px] h-auto fill-brand-red-surface stroke-[#856D6D] absolute z-50 overflow-clip"
          :class="tipPosition"
        >
          <polygon points="0,-3 40,-3 20,24" />
        </svg>

        <div
          class="w-full h-full flex items-center justify-between z-40 rounded-2xl bg-brand-red-surface"
        >
          <slot name="content"></slot>
        </div>
      </div>
    </template>
  </tippy>
</template>

<script setup>
const props = defineProps({
  placement: {
    type: String,
    default: () => "top",
  },
  tipClasses: {
    type: String,
    default: () => "px-4 w-auto h-[56px]",
  },
});

const arrowPlacement = ref(props.placement);

/**
 * Janky way to get latest position as placement can change upon
 * where element is placed in viewport regardless of specified in props.
 */
const onInit = (e) => {
  const element = document
    .getElementById(e.popper.id)
    .getElementsByClassName("tippy-box");
  arrowPlacement.value = element[0].getAttribute("data-placement");
};

const tipPosition = computed(() => {
  switch (arrowPlacement.value) {
    case "top": {
      return "-bottom-[14px]";
    }
    case "bottom": {
      return "-top-[14px] rotate-180";
    }
    case "left": {
      return "-right-[19px] -rotate-90";
    }
    case "right": {
      return "-left-[19px] rotate-90";
    }
    case "bottom-end": {
      return "-top-[14px] right-[4px] rotate-180";
    }
    case "bottom-start": {
      return "-top-[14px] left-[4px] rotate-180";
    }
    // Hide for unknown to keep UI pretty
    default: {
      return "hidden";
    }
  }
});
</script>
